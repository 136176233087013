import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  HashRouter,
} from 'react-router-dom';


// Main Page
const Home = React.lazy(() => import('./screens/Home'));

// 404 Page
const Sui = React.lazy(() => import('./screens/Sui'));


export default function Navigation() {

  return (
    <HashRouter>
      <Routes>
        <Route
          path="/"
          element={<Home />}
          exact
        />
        
        <Route path="/suistimal" element={<Sui />} exact/>
      </Routes>
    </HashRouter>
  );
}

import React,{useEffect, useState} from 'react';

import Navigation from './router';

function App() {


  const [ready,setReady] = useState(true)

  // useEffect(() => {
  //   if(products){
  //     setReady(true)
  //   }
  // },[products])

  // useEffect(() => {
  //   dispatch(getDataFromFakeApi())
  // } ,[])



  return (
    ready ? <Navigation /> : <h1>Loading...</h1>
  );
}

export default App;
